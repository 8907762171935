import styled from 'styled-components'

import { media } from '../../presentational'

export const StyledContainer = styled.div`
  max-width: 1080px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
`

export const StyledTitle = styled.h1`
  font-family: CirceBold, sans-serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: var(--static-white);
  margin-bottom: 40px;
  margin-top: 0;
  padding-top: 60px;

  ${media.phone`
    padding-top: 20px;
  `};
`

export const StyledButtonWrapper = styled.div`
  max-width: 220px;
  margin-bottom: 20px;
`

export const StyledNotFoundSvgWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  height: auto;
`
