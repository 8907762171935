// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components'

import CirceBoldWoff from './Circe-Bold.woff'
import CirceBoldWoff2 from './Circe-Bold.woff2'

export const circeBoldFamily = 'CirceBold'

export const CirceBoldFont = styled.style`
  @font-face {
    font-family: ${circeBoldFamily};
    src: url(${CirceBoldWoff2}) format('woff2'),
      url(${CirceBoldWoff}) format('woff');
    font-display: swap;
  }
`
