import React, { FC } from 'react'

export const Error500Svg: FC<{
  width?: string | number
  height?: string | number
}> = ({ width = 1080, height = 624 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 1080 624"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M152.133 284.573C177.026 282.872 197.413 280.792 213.292 278.335C225.577 276.434 233.873 274.535 238.182 272.639C242.79 270.697 245.495 267.36 246.296 262.627C247.351 257.548 246.949 249.007 245.092 237.004C243.281 225.301 241.255 217.165 239.014 212.596C236.773 208.027 233.162 205.513 228.183 205.055C223.456 204.25 215.101 204.775 203.116 206.629C183.341 209.69 163.729 213.8 144.28 218.961L116.696 40.7205L280.286 15.404L290.665 82.4692L198.983 96.6575L205.809 140.768C214.565 137.876 224.786 135.526 236.471 133.718C264.035 129.452 284.442 134.437 297.689 148.671C310.936 162.905 320.044 186.076 325.013 218.183C329.239 245.49 329.839 267.212 326.814 283.35C323.79 299.488 315.794 312.247 302.826 321.628C290.158 330.963 270.791 337.647 244.724 341.681C232.74 343.536 218.968 344.745 203.409 345.309C187.896 346.174 173.962 346.333 161.606 345.787L152.133 284.573Z"
        fill="#0C345B"
      />
      <path
        d="M483.654 369.917C452.947 370.863 430.769 367.15 417.121 358.778C403.473 350.407 394.508 335.979 390.225 315.495C386.237 294.699 383.51 260.524 382.046 212.971C380.581 165.418 381.204 131.291 383.914 110.592C386.918 89.5806 394.974 74.477 408.081 65.2812C421.188 56.0854 443.095 51.0146 473.802 50.0688C504.509 49.123 526.535 52.8405 539.879 61.2216C553.527 69.5933 562.345 84.1772 566.333 104.973C570.616 125.457 573.49 159.476 574.954 207.029C576.419 254.582 575.649 288.865 572.644 309.876C569.934 330.575 562.026 345.523 548.919 354.719C536.116 363.905 514.361 368.971 483.654 369.917ZM481.583 302.676C487.359 302.498 491.118 301.018 492.858 298.236C494.589 295.151 495.439 288.152 495.407 277.239C495.67 266.013 495.279 243.439 494.234 209.515C493.189 175.592 492.194 153.188 491.25 142.303C490.601 131.105 489.323 124.172 487.414 121.502C485.497 118.529 481.65 117.132 475.873 117.31C470.097 117.488 466.191 119.124 464.156 122.218C462.416 125 461.414 132.004 461.151 143.23C461.183 154.143 461.721 176.561 462.766 210.485C463.811 244.408 464.658 266.968 465.308 278.166C466.251 289.051 467.682 295.98 469.6 298.953C471.812 301.613 475.806 302.854 481.583 302.676Z"
        fill="#0C345B"
      />
      <path
        d="M677.041 340.276C650.349 336.12 631.783 329.318 621.344 319.872C610.904 310.427 605.493 296.505 605.109 278.108C605.031 259.489 608.21 229.511 614.647 188.176C621.084 146.84 627.149 117.447 632.844 99.9962C638.844 82.3234 648.253 70.5748 661.07 64.7503C673.888 58.9258 693.642 58.0917 720.335 62.2482C747.027 66.4046 765.461 73.1852 775.636 82.59C786.075 92.036 791.334 106.069 791.412 124.688C791.796 143.085 788.769 172.951 782.332 214.287C775.896 255.623 769.677 285.127 763.677 302.8C757.982 320.25 748.726 331.888 735.909 337.712C723.356 343.578 703.733 344.433 677.041 340.276ZM686.143 281.827C691.164 282.609 694.652 281.939 696.607 279.816C698.602 277.43 700.47 271.519 702.21 262.081C704.256 252.422 707.574 232.849 712.166 203.361C716.758 173.873 719.527 154.349 720.475 144.788C721.727 135.006 721.745 128.806 720.529 126.19C719.353 123.31 716.255 121.479 711.233 120.697C706.212 119.915 702.571 120.697 700.311 123.042C698.357 125.164 696.357 131.055 694.311 140.714C692.571 150.151 689.405 169.614 684.813 199.102C680.221 228.589 677.299 248.225 676.047 258.007C675.099 267.568 675.213 273.788 676.389 276.668C677.87 279.325 681.121 281.045 686.143 281.827Z"
        fill="#0C345B"
      />
      <mask
        id="mask0_114_2020"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="1080"
        height="624"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 -3.05176e-05H1080V624H0V-3.05176e-05Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_114_2020)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6882 720.481C21.9317 700.825 11.6882 687.065 17.833 677.236C23.9794 667.407 31.1489 658.234 31.1489 651.027C31.1489 643.818 31.1611 627.631 45.5017 617.803C59.8407 607.974 53.6837 606.47 57.7808 597.952C61.8779 589.434 62.9026 579.606 69.0474 573.709C75.1922 567.811 73.1444 556.673 86.4604 551.429C99.7763 546.188 111.043 542.911 116.163 534.393C121.285 525.875 124.357 518.013 129.479 512.771C134.601 507.529 155.086 497.702 162.256 492.458C169.427 487.216 188.888 466.904 203.227 464.939C217.567 462.972 238.053 451.179 244.197 449.213C250.344 447.248 267.757 446.592 287.218 436.109C306.678 425.624 296.436 423.659 307.703 420.382C318.97 417.106 327.164 407.933 350.722 408.588C374.281 409.244 377.354 408.588 388.62 406.623C399.887 404.657 407.058 399.415 419.349 398.104C431.64 396.795 461.345 396.138 472.611 394.173C483.878 392.207 489 386.965 501.291 385.655C513.582 384.345 570.941 385 581.183 387.62C591.427 390.241 612.935 390.897 620.106 392.207C627.276 393.518 664.15 407.277 685.66 403.347C707.17 399.415 734.825 408.588 739.945 409.899C745.067 411.209 765.552 424.968 771.699 427.59C777.843 430.212 808.572 435.454 819.839 437.418C831.107 439.384 858.762 446.592 866.956 455.11C875.15 463.629 891.539 476.734 902.805 479.354C914.072 481.975 938.654 493.114 944.801 499.666C950.946 506.22 963.237 523.911 967.334 527.187C971.431 530.463 1006.26 563.879 1013.43 573.052C1020.6 582.227 1026.74 596.641 1032.89 601.229C1039.03 605.815 1050.3 623.506 1057.47 630.059C1064.64 636.611 1064.64 653.648 1063.62 664.131C1062.59 674.616 1061.57 690.995 1069.76 697.548C1077.96 704.102 1082.05 726.379 1078.98 737.518C1075.91 748.656 1064.64 756.52 1066.69 767.004C1068.74 777.488 1072.83 791.902 1062.59 795.833C1052.35 799.765 15.7852 797.145 15.7852 797.145C15.7852 797.145 1.44627 740.138 11.6882 720.481Z"
          fill="#BECFE5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M275 569.328C275 569.328 282.912 572.444 285.202 573C306.89 578.268 322.425 580.881 331.953 582.32C347.805 584.714 359.039 583.847 361.278 583.847C367.983 583.847 367.444 575.71 360.318 573C353.192 570.29 350.451 567.08 334.51 563.22C326.861 561.367 303.4 562.175 301.493 562.175C295.611 562.175 275 569.328 275 569.328Z"
          fill="#0C345B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M404 567.328C404 567.328 411.912 570.444 414.202 571C435.89 576.268 451.425 578.881 460.953 580.32C476.805 582.714 488.039 581.847 490.278 581.847C496.983 581.847 496.444 573.71 489.318 571C482.192 568.29 479.451 565.08 463.51 561.22C455.861 559.367 432.4 560.175 430.493 560.175C424.611 560.175 404 567.328 404 567.328Z"
          fill="#0C345B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M364.143 152.563C365.123 151.159 364.222 149.192 362.535 149.076C359.292 148.852 354.709 147.782 353.98 143.586L353.447 139.529C353.447 139.529 353.32 136.137 357.327 133.463C361.331 130.787 364.877 128.614 364.877 128.614C364.877 128.614 368.064 127.068 366.629 123.468C365.189 119.868 363.954 116.518 363.954 116.518L365.668 113.898C365.668 113.898 367.233 112.318 364.819 109.854C362.407 107.39 354.272 99.0311 351.824 94.7241C351.824 94.7241 349.033 89.7829 341.492 93.21C333.951 96.6371 322.405 105.195 327.729 119.325C333.054 133.452 336.065 133.174 336.563 139.756C337.06 146.337 337.061 149.829 330.159 152.356C326.864 153.562 324.034 154.568 321.973 155.289C320.661 155.747 320.68 157.642 322.005 158.07C325.752 159.279 332.534 160.617 342.958 159.691C356.379 158.501 361.595 156.204 364.143 152.563Z"
          fill="#E37F43"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M352.791 66.8977C352.791 66.8977 357.399 81.0264 353.305 92.9706C353.305 92.9706 351.977 95.8568 347.735 97.3156C343.493 98.7745 340.142 102.651 341.89 107.822C343.638 112.993 345.896 116.509 341.404 115.143C336.911 113.776 334.835 113.761 334.209 115.947C333.582 118.135 337.322 122.22 340.937 124.243C344.553 126.269 340.137 132.011 340.137 132.011C340.137 132.011 336.279 137.54 333.536 133.558C330.792 129.577 322.038 121.408 322.038 121.408C322.038 121.408 316.516 116.383 315.149 107.495C313.78 98.6092 322.035 89.784 331.971 81.2848C331.968 81.2878 345.41 55.5274 352.791 66.8977Z"
          fill="#11042F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M364 130C364 130 354.289 134.345 352.427 136.067C350.564 137.789 349.889 138.234 350.015 142.294C350.142 146.353 354.261 147.452 355.606 146.844L355.702 146.781C355.702 146.781 354.656 145.722 354.413 143.955C354.169 142.19 353.935 140.379 353.935 140.379C353.935 140.379 353.866 139.649 354.024 139.007C354.182 138.364 354.641 135.84 359.089 133.149L364 130Z"
          fill="#CC6A3A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M274.159 175.266C263.435 185.267 239.259 207.328 229.446 216.331C219.861 225.126 216.681 239.907 214.93 254.749C212.577 274.69 215.199 294.024 217.162 296.45C220.586 300.686 226.252 294.897 226.252 294.897C226.252 294.897 223.177 243.9 244.949 222.453C252.275 215.237 265.568 206.627 277.815 199.501C283.185 196.377 290.024 193.658 294.901 195.622C300.229 197.766 301.999 226.758 303.21 244.05C304.269 259.166 307.701 262.426 306.597 280.551C306.126 288.314 307.424 296.727 307.424 296.727L387.842 292.914C387.842 292.914 392.946 222.914 395.767 213.851C396.114 212.733 396.446 211.263 396.759 209.578C399.723 193.617 397.891 178.565 407.186 172.901C431.111 158.318 441.342 148.015 451.889 138.029C460.743 129.647 459.182 126.249 452.27 115.593C449.87 111.892 438.766 107.196 426.314 100.998C412.994 94.3706 398.908 90.9786 387.685 86.1829C379.98 82.8888 374.446 81.6041 374.446 81.6041C368.152 78.9245 362.002 85.5473 368.937 93.8088C374.835 102.356 434.001 112.437 437.414 124.99C433.183 135.988 394.122 156.415 384.001 151.571C379.786 149.553 373.752 148.366 367.646 148.949C354.963 150.162 341.998 151.983 321.519 151.467C309.382 151.162 302.608 154.459 299.453 156.001C289.928 160.661 284.609 165.521 274.159 175.266Z"
          fill="#FFBE00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M306.876 290.723C306.876 290.723 307.341 316.004 300.649 341.844C293.282 370.296 287.865 403.35 285.612 415.777C284.086 424.19 274.208 456.839 269.715 482.733C265.977 504.272 265.123 520.859 265 525.396C266.989 533.98 288.064 532.689 295.037 532.955C304.167 533.303 304.356 531.645 306.876 527.794C309.396 523.942 318.68 436.654 322.131 428.426C325.175 421.172 327.951 408.033 327.918 397.853C327.909 395.542 344.234 326.492 348.014 325.721C351.794 324.952 354.313 328.803 354.313 328.803C354.313 328.803 358.95 339.424 363.545 360.589C369.385 387.49 375.961 419.182 375.712 424.364C375.515 428.467 378.964 439.918 380.088 451.721C383.006 482.35 393.829 528.775 395.207 529.898C395.982 530.531 401.398 532.299 406.902 532.637C414.821 533.126 424.04 532.032 429.295 531.927C434.701 531.82 439 525.298 439 525.298C439 525.298 428.459 476.511 424.568 460.673C420.337 443.455 419.342 430.737 418.347 416.757C417.352 402.776 407.15 378.332 407.15 378.332C407.15 378.332 402.492 348.146 394.505 326.063C386.623 304.272 388.1 290 388.1 290L306.876 290.723Z"
          fill="#FFBE00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M230.287 301.009C230.287 301.009 229.803 301.666 229.933 303.215C230.062 304.764 233.041 307.538 227.623 308.154C224.976 308.458 230.941 313.796 242.186 317.066C258.258 321.743 281.395 320.082 286.851 317.244C292.307 314.404 291.045 313.981 290.395 313.595C289.747 313.207 283.474 312.792 285.162 311.243C286.853 309.694 290.082 306.067 289.692 305.42C289.302 304.775 282.749 308.436 281.321 308.824C279.891 309.211 268.331 310.63 265.603 310.244C262.875 309.856 251.574 311.405 244.04 307.534C236.506 303.66 232.87 303.403 231.83 302.113C230.791 300.823 230.287 301.009 230.287 301.009Z"
          fill="#C43652"
        />
        <path
          opacity="0.748558"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M219.359 256.85C218.118 270.932 220.064 283.212 225.678 292.663C226.213 293.563 226.914 293.035 227.53 293.878C228.428 295.108 228.589 297.927 229.668 299.028C237.119 306.637 249.001 311.075 266.965 310.999C308.035 310.819 308.966 266.204 305.957 254.234C303.847 245.829 294.324 217.002 263.187 217C259.532 216.999 255.586 217.396 251.312 218.269C251.312 218.269 222.087 225.95 219.359 256.85Z"
          fill="#0E6EAF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M234.25 242.643C234.25 242.643 227.715 250.909 230.852 254.314C233.995 257.72 242.821 247.448 243.965 245.384C245.11 243.321 247.694 238.091 244.348 236.354C243.878 236.11 243.38 236 242.864 236C239.695 236 235.868 240.133 234.25 242.643Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M251.795 229.168C249.166 231.26 250.201 232.927 250.201 232.927C251.077 234.371 253.353 234.302 255.564 233.051C257.781 231.799 258.621 230.055 257.514 229.155C256.865 228.625 255.91 228 254.699 228C253.852 228 252.877 228.307 251.795 229.168Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M229.908 271.642C231.683 265.309 232.969 260.852 231.037 261.004C229.005 261.163 227.275 262.243 225.945 263.796C223.563 266.575 226.307 283.176 227.369 283.934C228.485 284.732 228.087 278.142 229.908 271.642Z"
          fill="#FFBE00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M280.725 306.203C279.463 308.506 269.723 309.593 259.109 308.674C248.495 307.755 240.241 304.91 239.232 302.363C238.422 300.321 239.612 297.684 244.784 299.753C254.187 303.515 270.152 303.638 275.326 303.273C280.128 302.936 281.699 304.426 280.725 306.203Z"
          fill="#0C345B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M308.631 173.62C309.382 168.222 319.589 161.206 324.874 160.412C343.29 157.643 378.571 154.166 384.481 160.852C396.318 174.242 388.619 226.728 387.604 239.691C386.084 259.088 382.751 295.815 374.39 298.143C366.03 300.47 322.072 304.961 317.133 299.142C312.193 293.324 310.525 254.552 310.282 234.322C310.007 211.278 306.628 188.023 308.631 173.62Z"
          fill="#C43652"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M368.306 170.068C379.723 185.291 368.306 284.248 355.463 287.102C342.619 289.956 320.738 291.86 317.408 287.102C314.078 282.345 315.506 257.13 314.078 246.188C313.391 240.914 312 186.707 312 184.279C312 161.92 362.047 161.719 368.306 170.068Z"
          fill="#E06065"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M379.22 249.019C379.22 249.019 377.328 249.224 375.9 254.804C374.559 260.042 374.431 265.623 377.516 264.944C380.601 264.264 382.43 257.911 382.43 257.911C384.407 249.621 380.701 249 379.547 249C379.345 249.001 379.22 249.019 379.22 249.019Z"
          fill="#0C345B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M369.09 281.587C368.372 289.809 372.175 288.976 372.175 288.976C372.175 288.976 376.466 287.803 377.764 279.004C379.061 270.203 374.594 270.001 374.594 270.001C374.569 270 374.544 270 374.518 270C372.432 270 369.793 273.467 369.09 281.587Z"
          fill="#0C345B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M304.426 533.214C304.426 533.214 324.211 549.126 336.168 551.63C348.121 554.134 335.855 570.329 321.541 570.924C307.225 571.52 268.665 568.548 261.97 564.174C261.97 564.174 260.395 562.812 261.259 553.098C262.12 543.384 265.951 527.313 265.951 527.313C265.951 527.313 284.584 519.257 304.426 533.214Z"
          fill="#FFBE00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M258.901 561.764C258.901 561.764 293.094 569.743 305.53 567.406C305.53 567.406 311.391 566.255 316.729 560.246C316.729 560.246 327.381 548.969 337.433 551.323C347.482 553.68 351.172 559.367 351.978 565.91C351.978 565.91 352.554 572.806 347.847 573.967C343.143 575.127 290.859 577.036 261.465 568.458C261.465 568.458 258.799 567.455 258.175 564.876C258.171 564.876 257.47 561.826 258.901 561.764Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M261.945 537.853C261.945 537.853 261.983 534.604 267.285 534.04C272.587 533.479 277.723 538.848 279.621 548.401C281.519 557.954 276.028 565.194 268.945 563.836C261.863 562.478 259.24 560.762 259.015 556.842C258.792 552.916 261.067 541.309 261.945 537.853Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M436.857 527.252C436.857 527.252 459.255 537.936 471.172 537.621C483.086 537.304 475.135 555.502 461.685 559.302C448.236 563.104 410.967 568.987 403.618 566.334C403.618 566.334 401.814 565.393 400.421 555.946C399.027 546.497 399 530.327 399 530.327C399 530.327 414.847 518.441 436.857 527.252Z"
          fill="#FFBE00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M398.077 541.558C398.077 541.558 397.129 538.832 401.901 537.336C406.673 535.846 413.083 539.351 417.741 546.986C422.4 554.619 419.47 561.74 412.459 561.967C405.445 562.195 402.482 561.263 401.087 558.023C399.692 554.779 398.302 544.622 398.077 541.558Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M401.029 564.666C401.029 564.666 401.391 568.214 404.567 568.844C407.743 569.476 455.8 568.896 484.589 553.067C484.589 553.067 489.228 549.845 485.636 544.908C482.044 539.968 475.796 535.046 462.731 536.159C462.731 536.159 459.684 536.54 456.621 543.484C453.56 550.425 449.639 556.702 444.64 558.394C439.642 560.085 408.011 563.981 403.112 563.152C403.112 563.152 400.714 563.096 401.029 564.666Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M367.35 93.0157C367.35 93.0157 361.805 91.9688 358.231 91.2531C354.658 90.5358 348.5 92.274 344.944 95.6451C344.944 95.6451 343.603 97.9434 341.606 96.5532C339.61 95.1614 339.894 93.4552 340.855 90.4915C341.815 87.5233 337.634 86.5634 334.229 86.716C330.821 86.8701 327.17 90.1283 324.887 92.7044C324.887 92.7044 323.389 93.9207 322.329 92.2542C321.271 90.5861 322.782 86.8945 326.563 83.1052C326.563 83.1052 325.26 82.2857 326.271 80.5063C327.279 78.7284 333.772 74.2096 343.816 73.0971C353.86 71.9861 372 80.7703 372 80.7703C372 80.7703 371.442 93.6521 367.35 93.0157Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M227.714 322.107C232.776 326.71 239.611 328.166 243.117 326.046C246.415 324.052 246.006 318.877 236.789 316.627C232.589 315.601 229.375 307.404 229.422 305.849C229.518 302.72 236.153 310.462 236.789 311.654C237.75 313.45 243.104 314.391 239.611 309.146C236.119 303.9 232.467 298.891 231.374 297.882C229.1 295.78 226.558 295 226.558 295C226.558 295 226.124 296.859 222.444 298.214C220.484 298.936 219.017 297.015 219.017 297.015C218.728 298.745 222.199 317.09 227.714 322.107Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M771.905 519.703C771.905 519.703 785.99 522.76 794.36 523.048C802.73 523.336 811.014 523.336 809.846 521.241C808.678 519.146 792.173 516.262 779.847 513.281C773.933 511.851 769.198 512.636 767.723 512.636C763.176 512.636 771.905 519.703 771.905 519.703Z"
          fill="#0C345B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M813.887 295.9C813.887 295.9 824.616 300.041 839.086 302.233C850.378 303.943 863.341 302.715 874.147 303.677C887.11 304.83 899.214 307.542 914.285 311.292C914.752 318.742 912.987 336.722 908.182 373.919C908.018 375.189 908.018 378.158 906.049 377.506C894.518 373.689 882.947 371.222 876.369 370.16C863.522 368.085 850.31 367.12 837.019 366.777C811.936 366.128 800.893 362.989 803.89 357.358C804.644 341.989 805.534 331.371 806.562 325.506C810.599 302.474 813.887 295.9 813.887 295.9Z"
          fill="#F56323"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M870.444 351.8L882.522 352.857L884.371 331.717C885.419 319.737 873.408 317.917 863.504 321.741C857.346 316.898 849.204 318.107 841.2 320.325L838.689 349.022L850.768 350.078L852.658 328.481C855.085 327.851 856.475 328.585 856.329 330.263L854.566 350.411L866.644 351.467L868.534 329.87C870.963 329.24 872.353 329.974 872.206 331.652L870.444 351.8Z"
          fill="#FEFEFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M765 515.477C765 515.477 782.034 460.351 788.361 434.062C800.055 385.477 800.67 339.424 804.241 326.128C807.931 312.388 811.715 296.556 812.371 295.444C813.807 293.012 815 296.042 815 296.042C815 296.042 811.148 313.905 810.096 318.451C802.6 350.851 802.6 400.36 797.167 425.467C788.27 466.572 777.765 519.521 777.765 519.521C777.765 519.521 774.608 520.479 771.312 519.521C768.015 518.563 765 515.477 765 515.477Z"
          fill="#FFBE00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M624.6 494.943C620.892 486.5 635.366 476.824 640.909 467.201C654.091 458.364 684.875 458.364 699.27 471.159C708.623 481.631 718.78 499.423 718.78 499.423C718.78 499.423 720.987 513.538 701.074 511.812C681.16 510.085 670.705 505.303 655.572 503.567C640.439 501.831 627.242 501.566 624.6 494.943Z"
          fill="#ECF0F6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M857.751 584.25C869.966 581.141 885.16 569.879 894.038 561.338C912.91 555.195 953.689 561.66 970.717 577.588C981.434 590.113 992.05 610.19 992.05 610.19C992.05 610.19 1005.09 629.892 978.983 623.969C968.622 621.618 948.507 614.646 940.972 611.781C929.524 607.428 919.354 602.131 907.431 599.158C887.661 594.229 860.195 591.484 857.751 584.25Z"
          fill="#ECF0F6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M891.722 568.888C892.492 564.025 913.958 559.32 932.765 564.175C951.573 569.03 966.396 575.512 972.164 583.353C975.231 587.522 978.134 596.641 973.847 598.781C970.07 600.666 957.428 599.585 948.542 596.459C933.296 591.095 922.382 577.575 906.452 572.903C902.656 571.789 891.571 569.843 891.722 568.888Z"
          fill="#155493"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M862.729 457.955C878.237 460.667 883.886 461.838 900.495 451.827C903.997 453.507 898.117 458.92 909.252 466.622C920.854 474.647 927.271 466.622 929.845 470.399C925.406 487.876 941.757 497.238 941.757 497.238C941.757 497.238 918.707 499.154 914.036 497.238C909.365 495.322 899.61 485.143 891.748 479.018C883.886 472.893 861.177 464.53 862.729 457.955Z"
          fill="#BECFE5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M576.438 397.111C599.252 393.802 607.681 392.768 623.813 377.211C626.317 374.087 631.31 382.786 643.126 384.538C655.964 386.441 660.641 381.303 666.566 383.848C673.044 395.107 709.064 403.934 709.064 403.934C709.064 403.934 678.978 414.383 671.4 414.357C663.821 414.331 644.024 408.531 629.405 405.79C614.787 403.05 578.556 403.81 576.438 397.111Z"
          fill="#BECFE5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M177.131 479.049C184.347 469.351 182.098 466.761 188.197 458.326C189.554 456.449 197.263 455.636 201.109 452.946C204.149 450.819 203.483 446.954 204.362 444.405C205.164 440.611 212.729 446.718 223.105 443.919C234.379 440.879 236.154 434.429 242.023 434.552C251.828 442.451 284.852 437.113 284.852 437.113C284.852 437.113 264.392 457.896 258.176 460.696C251.96 463.496 233.431 465.561 220.365 468.499C207.299 471.436 181.543 484.396 177.131 479.049Z"
          fill="#BECFE5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M975.207 532.871C992.928 547.628 999.336 553.206 1021.68 554.797C1025.67 554.57 1022.56 564.105 1029.16 574.067C1036.34 584.891 1043.29 584.931 1045.36 591.042C1041.34 603.394 1058.89 636.096 1058.89 636.096C1058.89 636.096 1031 620.707 1025.95 615.052C1020.89 609.397 1011.95 590.789 1004.2 578.081C996.458 565.372 971.651 538.929 975.207 532.871Z"
          fill="#BECFE5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M101.149 571.421C90.2125 567.517 91.6673 546.419 92.2984 540.276C95.3099 520.672 121.393 492.673 145.402 490.606C162.994 491.124 170.491 494.452 170.491 494.452C170.491 494.452 196.131 506.971 177.664 523.596C159.198 540.22 148.743 540.305 134.318 552.572C119.893 564.84 109.501 574.724 101.149 571.421Z"
          fill="#ECF0F6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M101.585 545.228C95.047 537.876 100.7 523.036 114.418 511.583C128.137 500.13 141.521 492.984 151.88 493.969C162.24 494.953 166.57 507.219 151.011 517.513C135.452 527.807 108.124 552.58 101.585 545.228Z"
          fill="#155493"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M644 472.905C644 468.111 656.137 462.546 670.313 464.347C684.489 466.148 695.966 470.122 701.042 476.784C706.117 483.445 701.312 492.754 686.809 489.227C672.306 485.701 644 477.698 644 472.905Z"
          fill="#155493"
        />
      </g>
    </svg>
  )
}
