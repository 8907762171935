import React, { FC, useEffect, useMemo } from 'react'

import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { Route } from 'react-router-dom'

import { createBaseUrlAction } from 'actions/system/createBaseUrlAction'
import { showNotFoundAction } from 'actions/system/routeAction'
import { useShallowEqualSelector } from 'common/hooks/useShallowEqualSelector'
import { Colors } from 'common-constants/colors'
import { AppStaticContext } from 'components/components.types'
import { CirceBoldFont } from 'components/fonts/circe/CirceBold'
import { genericLayoutContextId } from 'components/layout/MambaLayout/Context'
import { WidthLimit } from 'components/layout/MambaLayout/WidthLimit'
import { WrapperElement } from 'components/layout/ModalLayout/WrapperElement'
import {
  StyledButtonWrapper,
  StyledContainer,
  StyledNotFoundSvgWrapper,
  StyledTitle,
} from 'components/page/NotFoundPage/NotFoundPage.styled'
import { indexPath, internalServerErrorPath } from 'components/paths'
import { Button } from 'components/presentational/button'
import { Error500Svg } from 'components/presentational/svg/Error500Svg'
import { NotFoundSvg } from 'components/presentational/svg/NotFoundSvg'
import { defineStartScreenPath } from 'functions/defineStartScreenPath'

import SvgBackgroundImageUrl from './BackgroundImage.svg'

const NotFoundPageIndex: FC<{
  internalServerError?: boolean
}> = ({ internalServerError }) => {
  const dispatch = useDispatch()
  const { pathname: pathnameRouter } = useLocation()
  const { authorized, startScreen, baseUrl } = useShallowEqualSelector(
    ({
      authorizationReducer: { authorized },
      systemReducer: { baseUrl, startScreen },
    }) => ({
      authorized,
      startScreen,
      baseUrl,
    })
  )

  useEffect(() => {
    return () => {
      if (!internalServerError) {
        dispatch(showNotFoundAction(false))
      }
    }
    // pathnameRouter смена пути триггер ухода со страницы
    // из-за особенности рендера по булеану (systemReducer.showNotFound)
    // pathname из синхронизации редакса не отдает правильный путь
  }, [pathnameRouter, baseUrl, dispatch, internalServerError])

  /**
   * Если GQL рендер поменял store, то компонент будет запущен
   * не из роута.
   *
   * А значит не получит staticContext, поэтому надо изменять
   * именно через <Route />
   */
  const route = (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          ;(staticContext as AppStaticContext).status = internalServerError
            ? 500
            : 404

          if (!internalServerError) {
            dispatch(showNotFoundAction(true))
          }

          dispatch(
            createBaseUrlAction({
              id: genericLayoutContextId,
              baseUrl: internalServerErrorPath,
              basePath: internalServerErrorPath,
              secondary: true,
            })
          )
        }
        return null
      }}
    />
  )

  const correctedPath = useMemo(
    () => (authorized ? defineStartScreenPath(startScreen) : indexPath),
    [authorized, startScreen]
  )

  return (
    <WrapperElement
      color={Colors.notFoundBackground}
      $backgroundImageUrl={SvgBackgroundImageUrl}
    >
      {route}
      <WidthLimit>
        <CirceBoldFont />
        <StyledContainer>
          <StyledTitle>
            <FormattedMessage
              id="notfound.page.title"
              defaultMessage="Упс..."
            />
            <br />
            <FormattedMessage
              id="notfound.page.subtitle"
              defaultMessage="Похоже, Вы попали куда-то не туда!"
            />
          </StyledTitle>
          <StyledButtonWrapper>
            {/* Обычный переход, если PWA удалена, чтобы произошла перезагрузка */}
            <Button href={correctedPath}>
              <FormattedMessage
                id="not.found.page.button.titile"
                defaultMessage="Перейти к знакомствам"
              />
            </Button>
          </StyledButtonWrapper>
        </StyledContainer>
        <StyledNotFoundSvgWrapper>
          {internalServerError ? (
            <Error500Svg width="100%" height="100%" />
          ) : (
            <NotFoundSvg width="100%" height="100%" />
          )}
        </StyledNotFoundSvgWrapper>
      </WidthLimit>
    </WrapperElement>
  )
}

export default NotFoundPageIndex
