import React from 'react'

export const NotFoundSvg = ({
  className,
  width = 1011,
  height = 610,
}: {
  className?: string
  width?: number | string
  height?: number | string
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 1011 610"
    version="1.1"
    className={className}
  >
    <g id="404" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M427.091088,50.9999765 L519.259468,52.7804934 C531.844138,52.7804934 545.268185,60.5165845 549.529407,77.4628035 C550.020416,79.4152951 550.18077,81.4327788 550.153378,83.4465131 L550.073114,87.2915058 C549.901413,97.0150146 549.560193,122.957963 549.531246,127.686684 L549.342381,290.719212 C549.342381,327.57311 504.83595,326.000021 499.861032,325.987517 L445.136572,325.859357 L443.892024,325.839037 C434.335901,325.604206 394.247731,322.896983 396.227151,291.733635 L396.651198,122.351507 L396.686826,118.22095 C396.805935,107.01375 397.2012,86.7807622 398.522346,77.3735581 C400.563184,62.8339907 413.313934,50.9807107 427.091088,50.9999765 Z M474.722213,97.951098 C462.616847,97.9229399 452.780574,107.554075 452.751755,119.460223 L452.399674,268.172949 C452.371045,280.077606 462.161815,289.752004 474.267181,289.780515 C486.374064,289.807201 496.208821,280.177557 496.23764,268.272901 L496.591218,119.560175 C496.61835,107.654027 486.827579,97.9796288 474.722213,97.951098 Z"
        id="Shape"
        fill="#0C345B"
      />
      <path
        d="M269.338617,8.66611091 L284.338795,165.53549 C284.842902,170.796985 289.546399,174.658591 294.847022,174.158205 L300.74027,173.602718 C306.040893,173.103822 310.74589,176.963939 311.248497,182.225433 L314.112604,212.174104 C314.61521,217.435598 310.726386,222.105863 305.425763,222.60476 L299.532514,223.160247 C294.231891,223.659143 290.343067,228.330898 290.845674,233.590903 L294.401427,270.768358 C294.905534,276.029852 291.01521,280.700117 285.714587,281.200503 L245.833137,284.956373 C240.532514,285.456758 235.827517,281.595152 235.32491,276.333658 L231.769157,239.157692 C231.26655,233.896198 226.561553,230.034591 221.26093,230.534977 L157.489919,236.542582 C153.440561,236.923828 149.586244,234.742088 147.854877,231.088975 L129.072398,191.451292 C127.890147,188.953832 127.849639,186.070658 128.965875,183.543413 L205.458082,10.016854 C206.860879,6.83136391 209.893022,4.65409108 213.379761,4.32645769 L258.830391,0.0433956986 C264.131014,-0.455500605 268.836011,3.40461646 269.338617,8.66611091 Z M215.389327,99.2346902 L184.221907,170.854108 C181.330009,177.500811 186.625659,184.815444 193.833957,184.129743 L216.591943,181.967491 C221.81807,181.469877 225.652239,176.825478 225.156696,171.593124 L218.751621,99.2717151 C218.140699,97.7759105 216.034272,97.7536956 215.389327,99.2346902 Z"
        id="Shape"
        fill="#0C345B"
      />
      <path
        d="M703.89597,28.1287612 L747.383361,35.4261318 C752.454821,36.277242 755.877682,41.0857147 755.026455,46.1654039 L729.690101,197.616562 C728.84037,202.696251 732.263231,207.504723 737.334691,208.355834 L742.973136,209.301345 C748.044596,210.152455 751.465961,214.960928 750.61623,220.040617 L745.779643,248.954388 C744.929912,254.034077 740.12923,257.460994 735.05777,256.611382 L729.419324,255.664372 C724.349361,254.813262 719.548678,258.241678 718.698947,263.321367 L712.69398,299.21484 C711.844249,304.293031 707.043566,307.721447 701.972107,306.870337 L663.814984,300.467531 C658.743524,299.614922 655.320663,294.806449 656.170394,289.728259 L662.175361,253.833287 C663.025093,248.755096 659.602231,243.946623 654.530772,243.095513 L593.516177,232.855219 C589.641522,232.204899 586.591166,229.188553 585.892531,225.3181 L578.30479,183.312516 C577.827564,180.664785 578.512735,177.940633 580.188261,175.838331 L695.077016,31.5107253 C697.186384,28.8629936 700.561373,27.5683471 703.89597,28.1287612 Z M681.955158,116.609149 L635.145521,176.430572 C630.773628,181.952403 633.912081,190.15631 640.845734,191.322559 L662.728916,195.001301 C667.667878,195.832874 672.344978,192.562295 673.306081,187.665229 L673.371814,187.326984 C673.545475,186.593285 674.960172,180.413732 676.370722,168.060214 L676.823897,164.010574 L677.027973,162.26128 C678.114864,153.106561 679.181384,146.685445 680.4121,139.92806 L681.661554,133.166668 L682.063509,130.917863 C683.499605,122.698693 683.912359,118.220768 683.912359,118.220768 C684.675704,114.388093 682.753836,115.632245 681.955158,116.609149 Z"
        id="Shape"
        fill="#0C345B"
      />
      <path
        d="M-1.70530257e-13,610 C1.56856769,601.952663 10.8400298,592.470008 14.9371162,583.952071 C19.0342026,575.434133 20.0588894,565.60556 26.2036887,559.708892 C32.3484879,553.811035 30.3007751,542.673016 43.6167211,537.429287 C56.9326672,532.187937 68.1992396,528.911349 73.3193521,520.393412 C78.4411253,511.875474 81.5135249,504.012853 86.6352982,498.771503 C91.7570714,493.528963 112.242503,483.70158 119.411989,478.457851 C126.583136,473.2165 146.043882,452.904037 160.382854,450.939274 C174.723487,448.972132 195.208919,437.178796 201.353718,435.212844 C207.500178,433.248081 224.91321,432.591574 244.373956,422.108873 C263.834701,411.623793 253.592815,409.65903 264.859388,406.382443 C276.12596,403.105856 284.320133,393.9326 307.877965,394.587918 C331.437457,395.244425 334.509857,394.587918 345.776429,392.623155 C357.043002,390.657202 364.214149,385.414662 376.505408,384.104027 C388.796667,382.794582 418.500959,382.138075 429.767531,380.173312 C441.034104,378.20736 446.155877,372.96482 458.447136,371.655374 C470.738395,370.344739 528.097605,371 538.339491,373.620137 C548.583037,376.241407 570.091495,376.896725 577.262642,378.20736 C584.432128,379.517994 621.305906,393.277283 642.816025,389.346567 C664.326144,385.414662 691.981062,394.587918 697.101174,395.898553 C702.222948,397.209188 722.70838,410.968476 728.85484,413.589746 C734.999639,416.212205 765.728618,421.453556 776.99519,423.418319 C788.263423,425.384271 815.918341,432.591574 824.112514,441.109512 C832.306687,449.628639 848.695033,462.733799 859.961605,465.35388 C871.228178,467.97515 895.810696,479.114357 901.957156,485.666343 C908.101955,492.219517 920.393215,509.910711 924.490301,513.187298 C928.587387,516.462696 963.413452,549.87913 970.582938,559.052385 C977.752424,568.22683 983.898884,582.641436 990.043684,587.228658 C996.190144,591.814691 1011.26996,610 1007.13284,610 C1002.99572,610 8.72649177,610 -1.70530257e-13,610 Z"
        id="Fill-24"
        fill="#BECFE5"
      />
      <path
        d="M232.156322,555.32759 C237.431225,557.405108 240.831929,558.629245 242.358436,559 C264.046433,564.267544 279.581636,566.880812 289.109809,568.319862 C304.961374,570.713941 316.195399,569.847076 318.434127,569.847076 C325.139639,569.847076 324.600506,561.709813 317.474281,559 C310.348056,556.290187 307.607619,553.080237 291.666753,549.219916 C284.017188,547.367458 260.556063,548.175347 258.648886,548.175347 C254.727866,548.175347 245.897012,550.559428 232.156322,555.32759 Z"
        id="Path-7"
        fill="#0C345B"
      />
      <path
        d="M361.156322,553.32759 C366.431225,555.405108 369.831929,556.629245 371.358436,557 C393.046433,562.267544 408.581636,564.880812 418.109809,566.319862 C433.961374,568.713941 445.195399,567.847076 447.434127,567.847076 C454.139639,567.847076 453.600506,559.709813 446.474281,557 C439.348056,554.290187 436.607619,551.080237 420.666753,547.219916 C413.017188,545.367458 389.556063,546.175347 387.648886,546.175347 C383.727866,546.175347 374.897012,548.559428 361.156322,553.32759 Z"
        id="Path-7-Copy"
        fill="#0C345B"
      />
      <path
        d="M321.299595,138.562761 C322.279698,137.159463 321.37866,135.191811 319.69145,135.076513 C316.448309,134.851985 311.865544,133.782444 311.136061,129.586203 L310.603493,125.529533 C310.603493,125.529533 310.476691,122.137344 314.483627,119.462733 C318.487579,116.786605 322.033553,114.614148 322.033553,114.614148 C322.033553,114.614148 325.220007,113.068244 323.78491,109.468215 C322.345337,105.868186 321.110138,102.518475 321.110138,102.518475 L322.8242,99.8984788 C322.8242,99.8984788 324.389083,98.3176822 321.975374,95.8539455 C319.563157,93.3902088 311.428451,85.031102 308.980431,80.724114 C308.980431,80.724114 306.1893,75.7829869 298.648324,79.2100689 C291.107349,82.637151 279.560929,91.1949951 284.885112,105.325071 C290.210786,119.452113 293.221209,119.174488 293.719465,125.755578 C294.21623,132.336668 294.217722,135.828984 287.31523,138.356438 C284.019876,139.562516 281.189959,140.56834 279.129803,141.288952 C277.817032,141.74711 277.836426,143.641942 279.161131,144.069759 C282.908496,145.278871 289.690153,146.616935 300.11475,145.691516 C313.534852,144.500609 318.751625,142.203751 321.299595,138.562761"
        id="Fill-11"
        fill="#E37F43"
      />
      <path
        d="M309.947087,52.8977002 C309.947087,52.8977002 314.555229,67.0264401 310.460907,78.9706641 C310.460907,78.9706641 309.133538,81.8568093 304.891566,83.3156573 C300.649594,84.7745052 297.297949,88.650744 299.046119,93.8220671 C300.79429,98.9933902 303.051852,102.509049 298.560352,101.143351 C294.067376,99.7761501 291.991423,99.7611259 291.365389,101.947144 C290.737879,104.134665 294.477842,108.21974 298.09378,110.243497 C301.709718,112.268757 297.293519,118.010999 297.293519,118.010999 C297.293519,118.010999 293.435436,123.539897 290.692107,119.558489 C287.948778,115.577081 279.194636,107.408434 279.194636,107.408434 C279.194636,107.408434 273.672542,102.382846 272.305307,93.49454 C270.936596,84.6092392 279.191683,75.7840353 289.127022,67.284856 C289.124069,67.2878608 302.566085,41.5273999 309.947087,52.8977002"
        id="Fill-13"
        fill="#11042F"
      />
      <path
        d="M321.156322,116 C321.156322,116 311.44525,120.34545 309.582979,122.067434 C307.720707,123.789418 307.045328,124.234047 307.170908,128.293773 C307.298021,132.353499 311.417684,133.452085 312.762317,132.843966 L312.8588,132.781321 C312.8588,132.781321 311.812804,131.722461 311.5693,129.954639 C311.325795,128.189873 311.09148,126.379269 311.09148,126.379269 C311.09148,126.379269 311.022564,125.648916 311.180305,125.007183 C311.338047,124.363922 311.797489,121.839771 316.244887,119.149076 L321.156322,116 Z"
        id="Fill-19"
        fill="#CC6A3A"
      />
      <path
        d="M231.315402,161.266352 C220.591481,171.266926 196.415438,193.328372 186.602595,202.33145 C177.016914,211.126112 173.837699,225.90723 172.08643,240.748996 C169.73345,260.690164 172.355325,280.023786 174.318236,282.45024 C177.742098,286.685664 183.408367,280.897067 183.408367,280.897067 C183.408367,280.897067 180.333424,229.899778 202.105807,208.453162 C209.431163,201.23741 222.724009,192.627094 234.971534,185.501281 C240.341419,182.37743 247.179899,179.657551 252.057669,181.62163 C257.385672,183.766453 259.155191,212.757716 260.36678,230.050353 C261.425799,245.166537 264.85714,248.425945 263.753248,266.551509 C263.282074,274.314444 264.580419,282.726549 264.580419,282.726549 L344.998554,278.914367 C344.998554,278.914367 350.102188,208.913903 352.923247,199.851122 C353.27027,198.733524 353.602336,197.263477 353.914956,195.578043 C356.879611,179.616883 355.047268,164.565466 364.342105,158.900662 C388.267259,144.317672 398.49846,134.01529 409.045272,124.02921 C417.89885,115.647229 416.338742,112.249251 409.426698,101.592913 C407.025956,97.8921896 395.92271,93.1958702 383.470262,86.9978733 C370.150256,80.3706105 356.064405,76.9786569 344.841496,72.1829285 C337.136684,68.8888777 331.602263,67.6040924 331.602263,67.6040924 C325.30798,64.9245696 319.158789,71.5473138 326.09327,79.8087993 C331.991168,88.3564622 391.157124,98.4374326 394.570516,110.990071 C390.338928,121.988315 351.277879,142.415346 341.157366,137.571419 C336.942231,135.553117 330.908216,134.366234 324.802403,134.949132 C312.119604,136.16162 299.1541,137.982611 278.675245,137.467492 C266.538408,137.161734 259.763977,140.458797 256.609357,142.001142 C247.084169,146.661313 241.765283,151.521334 231.315402,161.266352 Z"
        id="Fill-40"
        fill="#FFBE00"
      />
      <path
        d="M264.032269,276.72333 C264.032269,276.72333 264.497674,302.00386 257.805424,327.844146 C250.438262,356.295623 245.021011,389.349698 242.768812,401.776866 C241.242404,410.189703 231.364152,442.838594 226.871725,468.732905 C223.133522,490.272229 222.279033,506.859294 222.156322,511.395863 C224.145141,519.979778 245.220043,518.68919 252.193631,518.954811 C261.323644,519.302969 261.5122,517.644713 264.032269,513.793957 C266.552338,509.9417 275.836489,422.654388 279.287367,414.426136 C282.331204,407.171827 285.10717,394.033335 285.074248,383.852691 C285.065269,381.541637 301.390349,312.492144 305.170453,311.720792 C308.950556,310.952442 311.469129,314.803198 311.469129,314.803198 C311.469129,314.803198 316.106715,325.423542 320.700902,346.589195 C326.541657,373.490465 333.117181,405.181919 332.868766,410.363783 C332.671231,414.466654 336.120613,425.918377 337.244468,437.72126 C340.1626,468.35023 350.98513,514.775404 352.363386,515.897916 C353.138562,516.531205 358.554316,518.299012 364.058363,518.636666 C371.977725,519.125889 381.19603,518.03189 386.451661,517.926842 C391.85694,517.820293 396.156322,511.298319 396.156322,511.298319 C396.156322,511.298319 385.61513,462.511067 381.724287,446.672843 C377.493743,429.45549 376.498586,416.73719 375.503428,402.756813 C374.50827,388.776437 364.306778,364.331788 364.306778,364.331788 C364.306778,364.331788 359.648242,334.14552 351.661538,312.062948 C343.779588,290.271509 345.256612,276 345.256612,276 L264.032269,276.72333 Z"
        id="Fill-42"
        fill="#FFBE00"
      />
      <path
        d="M187.443757,287.009393 C187.443757,287.009393 186.959713,287.665673 187.089223,289.214687 C187.218733,290.763701 190.197465,293.538415 184.779087,294.154481 C182.132226,294.458494 188.097783,299.795594 199.342494,303.065735 C215.414692,307.743339 238.551664,306.081728 244.007275,303.244282 C249.462886,300.403619 248.201782,299.980575 247.550994,299.594528 C246.903444,299.206873 240.6303,298.791872 242.318787,297.242858 C244.008894,295.693844 247.238551,292.06661 246.848402,291.419981 C246.458253,290.774961 239.905044,294.435974 238.477195,294.82363 C237.047728,295.211285 225.487337,296.630008 222.759531,296.243961 C220.031725,295.856306 208.730354,297.40532 201.196106,293.533589 C193.661859,289.66025 190.025864,289.402885 188.986546,288.112845 C187.947227,286.822804 187.443757,287.009393 187.443757,287.009393"
        id="Fill-44"
        fill="#C43652"
      />
      <path
        d="M176.515528,242.850007 C175.273846,256.932476 177.220588,269.212321 182.834497,278.66278 C183.369385,279.56321 184.070575,279.035212 184.686355,279.878398 C185.584606,281.108373 185.745164,283.927143 186.824035,285.028397 C194.275521,292.636648 206.156922,297.075199 224.121795,296.999036 C265.191274,296.81916 266.122105,252.203536 263.113441,240.234519 C261.003343,231.828982 251.480476,203.001896 220.343622,203 C216.688055,202.998655 212.742106,203.395678 208.468674,204.269128 C208.468674,204.269128 179.243491,211.950301 176.515528,242.850007 Z"
        id="Clip-48"
        fill="#0E6EAF"
        opacity="0.748558408"
      />
      <path
        d="M191.406448,228.643192 C191.406448,228.643192 184.870973,236.90884 188.008598,240.313577 L188.008598,240.313577 C191.151195,243.719928 199.977319,233.4476 201.120986,231.384417 L201.120986,231.384417 C202.26631,229.321233 204.850334,224.090628 201.503866,222.35355 L201.503866,222.35355 C201.034797,222.109778 200.535893,222 200.020414,222 L200.020414,222 C196.851297,222 193.024157,226.132824 191.406448,228.643192 L191.406448,228.643192 Z"
        id="Clip-51"
        fill="#FFFFFF"
      />
      <path
        d="M208.95089,215.168213 C206.321992,217.26009 207.357311,218.927314 207.357311,218.927314 L207.357311,218.927314 C208.23361,220.371469 210.508943,220.30207 212.719991,219.05124 L212.719991,219.05124 C214.937806,217.798757 215.776888,216.055526 214.670518,215.154994 L214.670518,215.154994 C214.020907,214.624589 213.066789,214 211.855534,214 L211.855534,214 C211.007994,214 210.033576,214.307337 208.95089,215.168213 L208.95089,215.168213 Z"
        id="Clip-54"
        fill="#FFFFFF"
      />
      <path
        d="M187.064391,257.641682 C188.839749,251.30875 190.124884,246.852339 188.19345,247.003743 C186.160923,247.163288 184.431678,248.242654 183.10149,249.795768 C180.719566,252.57477 183.463301,269.175516 184.525678,269.934166 C185.641262,270.731887 185.24292,264.142298 187.064391,257.641682 Z"
        id="Fill-56"
        fill="#FFBE00"
      />
      <path
        d="M237.881243,292.203017 C236.619469,294.505568 226.879011,295.593053 216.265261,294.674159 C205.65151,293.755264 197.397072,290.91049 196.387969,288.363406 C195.577843,286.320575 196.768554,283.683881 201.940408,285.752532 C211.342919,289.514682 227.308552,289.637707 232.481985,289.273187 C237.284309,288.936006 238.855605,290.425982 237.881243,292.203017"
        id="Fill-60"
        fill="#0C345B"
      />
      <path
        d="M265.787602,159.619909 C266.53814,154.222117 276.745449,147.206339 282.030116,146.411618 C300.446245,143.642865 335.727393,140.166524 341.637507,146.851794 C353.473924,160.241865 345.775765,212.727996 344.760332,225.691411 C343.240125,245.087708 339.906856,281.814629 331.546457,284.143206 C323.186059,286.470281 279.22811,290.96068 274.289279,285.142241 C269.348976,279.323803 267.681606,240.55224 267.438785,220.322162 C267.163588,197.278261 263.784697,174.022535 265.787602,159.619909"
        id="Fill-84"
        fill="#C43652"
      />
      <path
        d="M325.462648,156.067635 C336.879121,171.291325 325.462648,270.247569 312.618928,273.102481 C299.775208,275.955889 277.894263,277.860167 274.564521,273.102481 C271.234779,268.344796 272.662026,243.130418 271.234779,232.187591 C270.547474,226.913976 269.156322,172.706748 269.156322,170.27902 C269.156322,147.919553 319.203214,147.719499 325.462648,156.067635"
        id="Fill-98"
        fill="#E06065"
      />
      <path
        d="M336.37672,235.019071 C336.37672,235.019071 334.484339,235.224455 333.055886,240.803549 L333.055886,240.803549 C331.715117,246.042293 331.587576,251.622854 334.672461,250.943622 L334.672461,250.943622 C337.757346,250.264389 339.585957,243.910706 339.585957,243.910706 L339.585957,243.910706 C341.562834,235.620551 337.857784,235 336.703543,235 L336.703543,235 C336.501072,235.001467 336.37672,235.019071 336.37672,235.019071 L336.37672,235.019071 Z"
        id="Clip-104"
        fill="#0C345B"
      />
      <path
        d="M326.246613,267.587042 C325.528355,275.809388 329.331225,274.976352 329.331225,274.976352 L329.331225,274.976352 C329.331225,274.976352 333.622678,273.802999 334.919996,265.003595 L334.919996,265.003595 C336.217315,256.20271 331.750474,256.00148 331.750474,256.00148 L331.750474,256.00148 C331.725419,256 331.700363,256 331.673916,256 L331.673916,256 C329.58874,256 326.94956,259.466791 326.246613,267.587042 L326.246613,267.587042 Z"
        id="Clip-107"
        fill="#0C345B"
      />
      <path
        d="M261.582535,519.214017 C261.582535,519.214017 281.367294,535.125979 293.324775,537.629892 C305.27782,540.133804 293.011388,556.329201 278.697594,556.923972 C264.380843,557.520211 225.820962,554.547824 219.126014,550.174421 C219.126014,550.174421 217.551689,548.811588 218.414981,539.098462 C219.276795,529.383868 223.106914,513.3133 223.106914,513.3133 C223.106914,513.3133 241.740124,505.256722 261.582535,519.214017"
        id="Fill-86"
        fill="#FFBE00"
      />
      <path
        d="M216.057272,547.764092 C216.057272,547.764092 250.25078,555.742691 262.68653,553.406228 C262.68653,553.406228 268.547514,552.255013 273.885355,546.245909 C273.885355,546.245909 284.537391,534.969035 294.589437,537.323254 C304.638528,539.680433 308.328613,545.366961 309.134018,551.910241 C309.134018,551.910241 309.710363,558.805692 305.003546,559.967265 C300.299685,561.127358 248.014858,563.036184 218.621268,554.458302 C218.621268,554.458302 215.955303,553.455059 215.331668,550.875923 C215.327235,550.875923 214.626754,547.82624 216.057272,547.764092"
        id="Fill-88"
        fill="#FFFFFF"
      />
      <path
        d="M219.101602,523.852929 C219.101602,523.852929 219.138993,520.603566 224.441115,520.039946 C229.743237,519.479301 234.879341,524.847815 236.777336,534.401092 C238.675331,543.95437 233.184756,551.193685 226.1013,549.835942 C219.01934,548.478198 216.395949,546.762058 216.1716,542.842002 C215.948746,538.915998 218.223648,527.309003 219.101602,523.852929"
        id="Fill-90"
        fill="#FFFFFF"
      />
      <path
        d="M394.012948,513.251929 C394.012948,513.251929 416.41165,523.936242 428.327937,523.621084 C440.24274,523.304446 432.291127,541.502254 418.841516,545.301911 C405.391904,549.104526 368.123077,554.987483 360.774106,552.33453 C360.774106,552.33453 358.970632,551.393493 357.576836,541.946139 C356.18304,532.497306 356.156322,516.326573 356.156322,516.326573 C356.156322,516.326573 372.003144,504.44081 394.012948,513.251929"
        id="Fill-92"
        fill="#FFBE00"
      />
      <path
        d="M355.232981,527.55826 C355.232981,527.55826 354.284823,524.831638 359.057627,523.33649 C363.828907,521.845837 370.238885,525.351494 374.897361,532.986036 C379.555837,540.619079 376.625997,547.739758 369.615417,547.967476 C362.601789,548.195193 359.638412,547.263348 358.243613,544.022862 C356.848814,540.779381 355.458588,530.621964 355.232981,527.55826"
        id="Fill-94"
        fill="#FFFFFF"
      />
      <path
        d="M358.184844,550.666327 C358.184844,550.666327 358.54741,554.214 361.722829,554.844335 C364.899733,555.476153 412.955988,554.896245 441.745757,539.066684 C441.745757,539.066684 446.384811,535.845302 442.791847,530.907927 C439.20037,525.967585 432.952062,521.046524 419.887821,522.158879 C419.887821,522.158879 416.840192,522.540047 413.777704,529.484112 C410.716701,536.42521 406.795349,542.701862 401.796702,544.394125 C396.798055,546.084906 365.167199,549.981116 360.268109,549.152041 C360.268109,549.152041 357.869829,549.095681 358.184844,550.666327"
        id="Fill-96"
        fill="#FFFFFF"
      />
      <path
        d="M324.50624,79.0157273 C324.50624,79.0157273 318.961798,77.9688271 315.387285,77.2530892 C311.814233,76.5358252 305.656016,78.2740458 302.1005,81.6451866 C302.1005,81.6451866 300.758962,83.9434837 298.762729,82.5532123 C296.766496,81.161415 297.050002,79.4552423 298.011584,76.4915684 C298.971705,73.5233163 294.790723,72.5634034 291.385728,72.7160128 C287.977811,72.8701482 284.325845,76.1283582 282.043184,78.7044042 C282.043184,78.7044042 280.545278,79.9207008 279.485784,78.2542066 C278.427752,76.5861862 279.938809,72.8945657 283.719376,69.1052752 C283.719376,69.1052752 282.415833,68.285763 283.427102,66.5063378 C284.435448,64.7284387 290.928319,60.2096755 300.972322,59.0971532 C311.016324,57.986157 329.156322,66.770352 329.156322,66.770352 C329.156322,66.770352 328.598078,79.6521084 324.50624,79.0157273"
        id="Fill-109"
        fill="#FFFFFF"
      />
      <path
        d="M184.870756,308.106578 C189.932294,312.710352 196.766853,314.165807 200.273582,312.045794 C203.57178,310.05185 203.162418,304.877442 193.945287,302.626755 C189.745486,301.601226 186.531349,293.40409 186.577949,291.848694 C186.674156,288.71987 193.30942,296.462282 193.945287,297.654001 C194.905852,299.449843 200.260364,300.390595 196.766853,295.145832 C193.274846,289.899566 189.623496,284.890742 188.530647,283.882365 C186.256257,281.779952 183.714292,281 183.714292,281 C183.714292,281 183.280053,282.858713 179.600142,284.214237 C177.639928,284.935579 176.173038,283.014986 176.173038,283.014986 C175.884417,284.744706 179.355469,303.090093 184.870756,308.106578 Z"
        id="Fill-58"
        fill="#FFFFFF"
      />
      <path
        d="M729.061768,505.702544 C738.451193,507.740621 745.935981,508.855687 751.516131,509.047743 C759.886357,509.335827 768.170229,509.335827 767.002038,507.240781 C765.833847,505.145736 749.329658,502.262143 737.003732,499.28107 C731.088873,497.850539 726.354387,498.635792 724.879704,498.635792 C721.847862,498.635792 723.241883,500.991376 729.061768,505.702544 Z"
        id="Path-7"
        fill="#0C345B"
      />
      <path
        d="M771.042827,281.899691 C771.042827,281.899691 781.772389,286.040693 796.242737,288.232706 C807.534275,289.943183 820.497345,288.715324 831.303653,289.676903 C844.266429,290.830371 856.370323,293.541707 871.441087,297.29209 C871.908353,304.742439 870.143053,322.721554 865.337837,359.919019 C865.173839,361.188538 865.173839,364.157852 863.205387,363.506281 C851.674504,359.68948 840.103344,357.221931 833.525593,356.159772 C820.678772,354.085299 807.465949,353.120341 794.175279,352.776669 C769.092723,352.128082 758.049815,348.988676 761.046556,343.358452 C761.800005,327.988691 762.690673,317.371357 763.71856,311.506449 C767.755325,288.473526 771.042827,281.899691 771.042827,281.899691 Z"
        id="Path-2"
        fill="#F56323"
      />
      <path
        d="M827.600066,337.799849 L839.678114,338.856542 L841.527563,317.717236 C842.575728,305.736653 830.564433,303.916979 820.660039,307.740865 C814.502324,302.898503 806.35983,304.107 798.356204,306.324751 L795.845547,335.021689 L807.924763,336.078483 L809.814315,314.480802 C812.241135,313.850965 813.631749,314.584729 813.484894,316.26329 L811.722222,336.410718 L823.80027,337.46741 L825.689822,315.869729 C828.118979,315.240096 829.509592,315.97386 829.362737,317.652421 L827.600066,337.799849 Z"
        id="Fill-4"
        fill="#FEFEFE"
      />
      <path
        d="M722.156322,501.477048 C733.512045,464.726124 741.299045,437.587891 745.517324,420.062349 C757.211381,371.477444 757.826088,325.423985 761.397121,312.127903 C765.08743,298.387721 768.871156,282.555536 769.527364,281.44427 C770.484985,279.822568 771.361305,280.021693 772.156322,282.041643 C769.588498,293.950457 767.953979,301.420266 767.252765,304.451069 C759.756576,336.851273 759.756576,386.359919 754.322884,411.466739 C748.392155,438.870159 741.924877,470.221605 734.921048,505.521078 C732.816779,506.159641 730.665717,506.159641 728.467862,505.521078 C726.270007,504.882516 724.16616,503.534506 722.156322,501.477048 Z"
        id="Path-6"
        fill="#FFBE00"
      />
      <path
        d="M581.756017,480.943333 C578.048309,472.5 592.522406,462.823855 598.06504,453.200635 C611.247052,444.364416 642.031035,444.364416 656.426694,457.159286 C662.66152,464.140714 669.164613,473.562104 675.935973,485.423456 C677.407851,494.833402 671.505886,498.962858 658.23008,497.811824 C638.316371,496.085273 627.861457,491.303001 612.728342,489.566805 C597.595228,487.830609 584.398337,487.565706 581.756017,480.943333 Z"
        id="Path-9"
        fill="#ECF0F6"
      />
      <path
        d="M814.907495,570.249975 C827.12213,567.141186 842.316224,555.879144 851.194031,547.338133 C870.066006,541.195251 910.844999,547.660553 927.872993,563.587668 C935.018129,571.937935 942.129287,582.805263 949.20647,596.189651 L949.712273,596.967508 C957.636317,609.377132 951.85986,609.377132 949.712273,610 L936.024595,610 C925.663784,607.648898 905.662975,600.645809 898.128455,597.781078 C886.679923,593.428184 876.510144,588.130983 864.587298,585.158192 C844.817776,580.228946 817.350996,577.483628 814.907495,570.249975 Z"
        id="Path-9-Copy-2"
        fill="#ECF0F6"
      />
      <path
        d="M848.878536,554.887836 C849.648676,550.025366 871.114124,545.31989 889.921778,550.174933 L891.274357,550.52873 C909.438549,555.343143 923.691688,561.700751 929.32076,569.353321 C932.387037,573.521841 935.290075,582.640874 931.003638,584.780674 C927.226735,586.666114 914.584421,585.585097 905.698113,582.458639 C890.452428,577.094769 879.538794,563.575246 863.60854,558.902711 L863.008348,558.735424 C858.765673,557.603126 848.734609,555.796561 848.878536,554.887836 Z"
        id="Path-10-Copy-2"
        fill="#155493"
      />
      <path
        d="M819.884938,443.954881 L823.228076,444.537035 C836.305534,446.780149 842.292513,447.08478 857.651826,437.826997 C861.153355,439.507338 855.272947,444.919574 866.408489,452.622152 C878.010293,460.647249 884.426916,452.622152 887.000835,456.398928 C884.041749,468.050186 888.012555,476.99655 898.913254,483.238022 L896.998193,483.393124 C882.776764,484.5133 874.174819,484.461599 871.19236,483.238022 C866.521301,481.321685 856.765949,471.142773 848.904143,465.017951 C841.042336,458.893129 818.333102,450.530152 819.884938,443.954881 Z"
        id="Path-9-Copy-3"
        fill="#BECFE5"
      />
      <path
        d="M533.594456,383.110581 L541.90468,374.499201 C548.989189,375.133447 566.289038,377.36752 580.969358,363.211119 C583.47333,360.087041 588.466403,368.786042 600.281985,370.537884 C613.120668,372.441417 617.797341,367.302535 623.722141,369.847536 C628.040734,377.353649 642.206785,384.049106 666.220294,389.933908 L663.531709,390.862162 C645.03714,397.208445 633.378569,400.373279 628.555995,400.356727 C620.977664,400.330551 601.180326,394.531087 586.561619,391.790364 C571.942912,389.049642 535.712413,389.809691 533.594456,383.110581 Z"
        id="Path-9-Copy-4"
        fill="#BECFE5"
      />
      <path
        d="M134.287162,465.049157 C141.503707,455.351348 139.254098,452.761428 145.353551,444.325579 C146.710698,442.448578 154.419285,441.635966 158.265255,438.945858 C161.305348,436.81943 160.639007,432.953576 161.518407,430.405305 C162.320079,426.610683 169.88578,432.717908 180.261296,429.919436 C191.53522,426.878646 193.310686,420.428895 199.179522,420.552122 C205.716109,425.818062 215.281618,427.005601 237.296909,423.447171 C237.051011,424.453143 236.86147,425.191154 236.728287,425.661205 C236.620037,426.043261 236.472669,426.588071 236.286184,427.295637 C223.97449,439.671778 219.232826,444.938825 215.332581,446.695783 C209.116566,449.495935 190.587246,451.561371 177.521102,454.49866 C164.454958,457.435948 138.699174,470.395986 134.287162,465.049157 Z"
        id="Path-9-Copy-6"
        fill="#BECFE5"
      />
      <path
        d="M930.397886,518.612555 L936.413064,522.238869 C951.212026,534.495319 958.280264,539.333242 978.83636,540.797331 C982.831137,540.569614 979.71408,550.104717 986.319693,560.066513 C993.49728,570.890892 1000.44165,570.931275 1002.51673,577.041658 C999.833947,585.276819 998.529161,588.198705 1010.2285,610 L998.019116,610 C981.661651,600.852324 986.239512,604.565079 983.101454,601.052141 C978.049946,595.397166 969.108421,576.789373 961.361289,564.080649 C953.614157,551.371926 926.841705,524.670678 930.397886,518.612555 Z"
        id="Path-9-Copy-5"
        fill="#BECFE5"
      />
      <path
        d="M58.5562931,557.515839 L58.3051037,557.420676 C47.3687984,553.516836 48.8235745,532.419176 49.4546889,526.276063 C52.4662361,506.671906 78.5493219,478.672884 102.558753,476.605666 L103.949949,476.651626 C114.950297,477.055272 122.849366,478.322107 127.647156,480.452132 C144.740546,488.798366 147.131674,498.512912 134.820541,509.595771 C116.566102,526.028975 106.141117,526.30054 91.9705593,538.154296 L91.4747,538.572541 C77.1939164,550.717733 66.8658665,560.526375 58.5562931,557.515839 L58.5562931,557.515839 Z"
        id="Path-9-Copy"
        fill="#ECF0F6"
      />
      <path
        d="M58.7417182,531.227805 C52.2033459,523.876078 57.8560506,509.035958 71.5746107,497.583176 C85.2931708,486.130394 98.6770314,478.983699 109.036521,479.968541 C119.292416,480.943534 123.639471,492.97454 108.628164,503.203551 L107.219914,504.147611 C91.5231861,514.785237 65.1493231,538.432497 58.7417182,531.227805 Z"
        id="Path-10-Copy"
        fill="#155493"
      />
      <path
        d="M601.156322,458.904605 C601.156322,454.110912 613.293796,448.545563 627.469652,450.347017 C641.645509,452.148471 653.122461,456.121921 658.197873,462.783683 C663.273286,469.445444 658.468144,478.753595 643.965182,475.227141 C629.462221,471.700686 601.156322,463.698297 601.156322,458.904605 Z"
        id="Path-10"
        fill="#155493"
      />
    </g>
  </svg>
)
